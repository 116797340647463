//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import { alertTriangleIcon } from "@debionetwork/ui-icons";
import { deleteGeneticAnalystServiceFee } from "@debionetwork/polkadot-provider";
export default {
  name: "DeleteServiceDialog",
  data: () => ({
    alertTriangleIcon,
    txWeight: 0
  }),
  props: {
    show: Boolean,
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      api: state => state.substrate.api,
      web3: state => state.web3Store.web3,
      wallet: state => state.substrate.wallet
    })
  },
  async created() {
    await this.getTxWeight();
  },
  methods: {
    async getTxWeight() {
      const getTxWeight = await deleteGeneticAnalystServiceFee(this.api, this.wallet, this.show);
      this.txWeight = "".concat(this.web3.utils.fromWei(String(getTxWeight.partialFee), "ether"));
    },
    closeDialog() {
      this.$emit("close");
    },
    handleSubmit() {
      this.$emit("submit");
    }
  }
};