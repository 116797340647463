import "core-js/modules/es.array.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import errorMessages from "@/common/constants/error-messages";
import { uploadFile, getFileUrl } from "@/common/lib/pinata-proxy";
import rulesHandler from "@/common/constants/rules";
import { validateForms } from "@/common/lib/validate";
import { getConversionCache } from "@/common/lib/api";
import { formatUSDTE } from "@/common/lib/balance-format.js";
const documentFormat = ["image/jpg", "image/png", "application/pdf", "application/msword"];
export default {
  name: "GAAddService",
  mixins: [validateForms],
  data: () => ({
    currency: [{
      currency: "USDT.e"
    }],
    service: {
      name: "",
      currency: "USDT.e",
      totalPrice: "",
      additionalPrice: "",
      duration: "",
      description: ""
    },
    durationType: "Days",
    testResultSample: null,
    file: {
      name: ""
    },
    error: false,
    errorFileMessage: "",
    loading: false,
    usdRate: 0,
    priceHint: ""
  }),
  props: {
    onSubmit: {
      type: Function
    },
    data: {
      type: Object,
      default: null
    },
    isBulk: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    editPage: {
      type: Boolean,
      default: false
    },
    txWeight: {
      type: Number,
      default: 0
    }
  },
  watch: {
    data: function (newVal) {
      this.service = {
        name: newVal.name,
        totalPrice: newVal.totalPrice,
        additionalPrice: newVal.additionalPrice,
        duration: newVal.duration,
        description: newVal.description,
        currency: formatUSDTE(newVal.currency)
      }, this.durationType = newVal.durationType;
      this.testResultSample = newVal.testResultSample;
      this.file = newVal.file;
    },
    isEdit: function (newVal) {
      this.isEdit = newVal;
    },
    txWeight: function (newVal) {
      this.txWeight = newVal;
    },
    service: {
      handler(val) {
        if (val.totalPrice !== "" && /^\d+(\.\d{1,4})?$/.test(val.totalPrice)) {
          this.priceHint = "".concat(this.service.totalPrice, " ").concat(this.service.currency, " = ").concat((this.usdRate.conversion * this.service.totalPrice).toFixed(4), " USD");
        } else {
          this.priceHint = "";
        }
      },
      deep: true
    }
  },
  async created() {
    this.usdRate = await getConversionCache(this.service.currency === "USDT.e" ? "USDT" : this.service.currency, "USD");
  },
  computed: {
    ...mapState({
      api: state => state.substrate.api,
      wallet: state => state.substrate.wallet
    }),
    disableService() {
      const {
        name,
        totalPrice,
        duration,
        description
      } = this.service;
      if (!name || !totalPrice || !duration || !description || !this.testResultSample) {
        return true;
      }
      return false;
    }
  },
  rules: {
    service: {
      name: [rulesHandler.FIELD_REQUIRED, rulesHandler.ENGLISH_ALPHABET, rulesHandler.MAX_CHARACTER(250)],
      description: [rulesHandler.FIELD_REQUIRED, rulesHandler.MAX_CHARACTER(5000), rulesHandler.ENGLISH_ALPHABET],
      currency: [rulesHandler.FIELD_REQUIRED],
      totalPrice: [rulesHandler.FIELD_REQUIRED, val => /^\d+(\.\d{1,4})?$/.test(val) || "Service price is invalid"],
      duration: [rulesHandler.FIELD_REQUIRED, val => /^[0-9]+$/.test(val) || "Maximum Duration is invalid", rulesHandler.MAX_CHARACTER(3)]
    }
  },
  methods: {
    handleSubmit() {
      var _this$isDirty;
      this._touchForms("service");
      const isDocumentValid = Object.values((_this$isDirty = this.isDirty) === null || _this$isDirty === void 0 ? void 0 : _this$isDirty.service).every(v => v !== null && v === false);
      if (!isDocumentValid) {
        if (!this.testResultSample) this.errorFileMessage = errorMessages.REQUIRED;
        return;
      }
      const service = {
        ...this.service,
        durationType: this.durationType,
        testResultSample: this.testResultSample,
        file: this.file
      };
      this.onSubmit(service);
    },
    handleCancelEdit() {
      this.$emit("cancelEdit");
    },
    handleChooseFile() {
      if (this.$refs["input-file"]) this.$refs["input-file"].click();
    },
    async onFileChange(event) {
      if (!event.target.value) return;
      const file = event.target.files[0];
      if (!documentFormat.includes(file.type)) return this.errorFileMessage = errorMessages.FILE_FORMAT("PNG/JPG/PDF/DOC");else if (file.size > 2000000) return this.errorFileMessage = errorMessages.FILE_SIZE(2);
      this.loading = true;
      this.errorFileMessage = "";
      const dataFile = await this.setupFileReader(file);
      const result = await uploadFile({
        title: dataFile.name,
        type: dataFile.type,
        size: dataFile.size,
        file: dataFile
      });
      const link = getFileUrl(result.IpfsHash);
      this.testResultSample = link;
      this.file = file;
      this.loading = false;
    },
    setupFileReader(value) {
      return new Promise((resolve, reject) => {
        const file = value;
        const fr = new FileReader();
        fr.onload = async function () {
          resolve(value);
        };
        fr.onerror = reject;
        fr.readAsArrayBuffer(file);
      });
    }
  }
};