//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters } from "vuex";
import { bulkCreateGeneticAnalystService, stakeGeneticAnalyst } from "@debionetwork/polkadot-provider";
import { pencilIcon, trashIcon } from "@debionetwork/ui-icons";
import localStorage from "@/common/lib/local-storage";
import AddServiceForm from "@/common/components/Service/AddService";
import DeleteDialog from "@/common/components/Dialog/DeleteServiceDialog";
import StakeDialog from "@/common/components/Dialog/StakeDialog";
import { toEther, formatUSDTE } from "@/common/lib/balance-format";
import { generalDebounce } from "@/common/lib/utils";
import { sendRegisteredEmail } from "@/common/lib/api";
const stepData = [{
  label: "Set Up Account",
  active: false
}, {
  label: "Set Up Service",
  active: true
}];
export default {
  name: "GARegistration",
  components: {
    AddServiceForm,
    DeleteDialog,
    StakeDialog
  },
  data: () => ({
    pencilIcon,
    trashIcon,
    stepData: stepData,
    services: [],
    service: {
      name: "",
      currency: "USDT.e",
      totalPrice: "",
      duration: "",
      durationType: "Days",
      description: "",
      testResultSample: null,
      file: {
        name: ""
      }
    },
    submitLoading: false,
    servicesLoading: false,
    showStakeDialog: false,
    modalDelete: null,
    editId: null,
    formatUSDTE
  }),
  computed: {
    ...mapState({
      api: state => state.substrate.api,
      web3: state => state.web3Store.web3,
      wallet: state => state.substrate.wallet,
      lastEventData: state => state.substrate.lastEventData
    }),
    ...mapGetters({
      pair: "substrate/wallet"
    })
  },
  watch: {
    lastEventData: {
      deep: true,
      immediate: true,
      handler: generalDebounce(async function (val) {
        if ((val === null || val === void 0 ? void 0 : val.method) === "GeneticAnalystStakeSuccessful") await sendRegisteredEmail();
      }, 100)
    }
  },
  async created() {
    this.getServices();
  },
  methods: {
    getServices() {
      const services = localStorage.getLocalStorageByName("temporaryServices");
      if (services) this.services = JSON.parse(services);
    },
    onSubmitService(value) {
      const {
        currency,
        description,
        duration,
        durationType,
        file,
        name,
        testResultSample,
        totalPrice
      } = value;
      const dataToSend = {
        name,
        pricesByCurrency: [{
          currency,
          totalPrice
        }],
        expectedDuration: {
          duration,
          durationType
        },
        description,
        testResultSample
      };
      const services = this.services;
      if (this.editId !== null) {
        services[this.editId] = {
          ...dataToSend,
          file: {
            name: file.name
          }
        };
        this.editId = null;
      } else {
        services.push({
          ...dataToSend,
          file: {
            name: file.name
          }
        });
      }
      localStorage.setLocalStorageByName("temporaryServices", JSON.stringify(services));
      this.clearForm();
    },
    editService(id) {
      this.editId = id;
      const data = this.services[id];
      const service = {
        name: data.name,
        currency: data.currency,
        totalPrice: data.pricesByCurrency[0].totalPrice,
        duration: data.expectedDuration.duration,
        durationType: "Days",
        description: data.description,
        testResultSample: data.testResultSample,
        file: data.file
      };
      this.service = service;
      const element = this.$refs["title"];
      const top = element.offsetTop;
      window.scrollTo(0, top);
    },
    cancelEdit() {
      this.clearForm();
      this.editId = null;
    },
    deleteService() {
      const services = this.services;
      services.splice(this.modalDelete, 1);
      localStorage.setLocalStorageByName("temporaryServices", JSON.stringify(services));
      this.services = services;
      this.modalDelete = null;
    },
    async onSubmit() {
      const services = [];
      const data = this.services;
      this.submitLoading = true;
      data.forEach(service => {
        const totalPrice = toEther(service.pricesByCurrency[0].totalPrice, service.pricesByCurrency[0].currency);
        delete service.file;
        services.push({
          ...service,
          pricesByCurrency: [{
            currency: formatUSDTE(service.pricesByCurrency[0].currency),
            totalPrice: totalPrice,
            priceComponents: [{
              component: "Main Price",
              value: totalPrice
            }]
          }]
        });
      });
      try {
        await stakeGeneticAnalyst(this.api, this.wallet);
        await bulkCreateGeneticAnalystService(this.api, this.wallet, services);
        localStorage.removeLocalStorageByName("temporaryServices");
        this.showStakeDialog = false;
        await this.goToDashboard();
      } catch (error) {
        console.error(error);
      }
      this.submitLoading = false;
    },
    async goToDashboard() {
      await this.$store.dispatch("substrate/getGAAccount");
      this.$router.push({
        name: "ga-dashboard"
      });
    },
    clearForm() {
      this.service = {
        name: "",
        currency: "",
        totalPrice: "",
        duration: "",
        durationType: "Days",
        description: "",
        testResultSample: null,
        file: {
          name: ""
        }
      };
    },
    limitChar(value, limit) {
      return value.length > limit ? "".concat(value.substring(0, limit), "...") : value;
    }
  }
};